import axios from "axios";

const token = localStorage.getItem("@token_mtechsp");

// const api = axios.create({
//   baseURL: 'http://10.115.102.5:8000/api/',
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });
const api = axios.create({
  // baseURL: 'http://10.115.101.113:8000/api/',
  // baseURL: 'http://10.115.101.13/api/',
  baseURL: "https://api.dtlab4.dtlab.com.br/api/",
  // baseURL: "https://dtlab4-dev.grupomtech.com.br/api/",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default api;
